export const SET_LOADING = "SET_LOADING";
export const FILTER_TICKETS = "FILTER_TICKETS";
export const СLEAR_FILTER = "СLEAR_FILTER";
export const TYPE_FILTER = "TYPE_FILTER";
export const CLEAR_TYPE_FILTER = "CLEAR_TYPE_FILTER";
export const SET_SEARCH_TYPE = "SET_SEARCH_TYPE";
export const SET_IMG_URL = "SET_IMG_URL";
export const GET_IMG = "GET_IMG";
export const CLEAR_IMG = "CLEAR_IMG";
export const ON_CHECK = "ON_CHECK";
export const SET_ERROR = "SET_ERROR";
export const GET_LOGS = "GET_LOGS";
export const LOGS_FILTER = "LOGS_FILTER";
export const REFRESH_LOGS = "REFRESH_LOGS";
export const RESET_FILTER = "RESET_FILTER";
export const GET_ARCHIVE = "GET_ARCHIVE";
export const SET_ARCHIVE_TYPE = "SET_ARCHIVE_TYPE";
export const CLEAR_ARCHIVE = "CLEAR_ARCHIVE";
export const GET_FINES = "GET_FINES";
export const SET_DATE = "SET_DATE";
export const RESET_FINES = "RESET_FINES";
export const GET_COLLATIONS = "GET_COLLATIONS";
export const SET_FINE_TYPE = "SET_FINE_TYPE";
export const GET_FILES = "GET_FILES";
export const GET_STATS = "GET_STATS";

// auth context
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_WITH_TOKEN = "LOGIN_USER_WITH_TOKEN";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOADING = "LOADING";
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const SET_USER = "SET_USER";
export const SET_KEY = "SET_KEY";
export const SET_LOGIN = "SET_LOGIN";
export const SET_TOKEN = "SET_TOKEN";
export const SET_PARTNER_ID = "SET_PARTNER_ID";

// fetch context
export const SET_FETCH_LOADING = "SET_FETCH_LOADING";
export const GET_NEWS = "GET_NEWS";

// bills context
export const SET_BILLS_LOADING = "SET_BILLS_LOADING";
export const CLEAR_BILLS = "CLEAR_BILLS";
export const SET_BILLS = "SET_BILLS";
export const SET_BILL = "SET_BILL";
export const UPDATE_BILL = "UPDATE_BILL";
export const SET_BILLS_FILTER = "SET_BILLS_FILTER";

//penalties
export const SET_PENALTIES = "SET_PENALTIES";
export const CLEAR_PENALTIES = "CLEAR_PENALTIES";
export const SET_PENALTY = "SET_PENALTY";
export const UPDATE_PENALTY = "UPDATE_PENALTY";
export const SET_PENALTY_FILTER = "SET_PENALTY_FILTER";
