const EnvTypes = {
  production: 'production',
  development: 'development'
}

const DEV_DOMEN = 'dev-partners.moika-moika.site'

export const API_URL = window.location.host === DEV_DOMEN || process.env.NODE_ENV === EnvTypes.development
  ? 'https://dev-api.moika-moika.site'
  : 'https://api.moika-moika.site'

export const AUTH_APU_URL = window.location.host === DEV_DOMEN || process.env.NODE_ENV === EnvTypes.development
  ? 'https://dev-api.ucar.pro'
  : 'https://api.ucar.pro'
