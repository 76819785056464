import React, { useReducer } from "react";
import BillsContext from "./billsContext";
import BillsReducer from "./BillsReducer";
import { urlParams } from "../../../utils/UrlParamsUtils";
import { getAuthData } from "../../../utils/authUtils";
import { API_URL } from "../../../utils/envUtils";
import { downloadFilePDF } from "../../../utils/fileUtils";
import {
  SET_BILLS_LOADING,
  SET_BILLS,
  CLEAR_BILLS,
  SET_BILL,
  UPDATE_BILL,
  SET_BILLS_FILTER,
  SET_PENALTIES,
  CLEAR_PENALTIES,
  SET_PENALTY,
  UPDATE_PENALTY,
  SET_PENALTY_FILTER,
} from "../types";

export const BillStatusTypes = {
  negotiation: "negotiation",
  accepted: "accepted",
  declined: "declined",
  paid: "paid",
};

export const PenaltyStatusTypes = {
  new: "new",
  agreed: "agreed",
  disagreed: "disagreed",
  canceled: "canceled",
  applied: "applied",
};

const BillsState = (props) => {
  const initialState = {
    loading: false,
    error: null,
    bills: [],
    bill: null,
    billsHasMore: true,
    billFilter: {
      start: undefined,
      end: undefined,
      state: undefined,
    },
    penalties: [],
    penalty: null,
    penaltiesHasMore: true,
    penaltyFilter: {
      state: undefined,
      begin: undefined,
      end: undefined,
    },
  };

  const [state, dispatch] = useReducer(BillsReducer, initialState);

  // bills
  const clearBills = () => dispatch({ type: CLEAR_BILLS });
  const setBill = (bill) => dispatch({ type: SET_BILL, payload: bill });
  const getAllBills = async (filter) => {
    dispatch({ type: SET_BILLS_LOADING, payload: true });
    const { token, contractor_id } = getAuthData();

    await fetch(
      `${API_URL}/partners/contractors/${contractor_id}/bills` +
        urlParams(filter),
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then(async (res) => {
      try {
        const data = await res.json();
        dispatch({
          type: SET_BILLS,
          payload: {
            bills: data,
            billsHasMore: !!data.length,
          },
        });
      } catch (error) {
        alert("Нет данных");
      }
    });
  };

  const getBill = async (billId) => {
    const { token, contractor_id } = getAuthData();

    try {
      const res = await fetch(
        `${API_URL}/partners/contractors/${contractor_id}/bills/` + billId,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await res.json();
      return data;
    } catch (error) {
      alert("Нет данных");
    }
  };

  const acceptOrDeclineBill = async (bill_id, type, body) => {
    const { token, contractor_id } = getAuthData();

    await fetch(
      `${API_URL}/partners/contractors/${contractor_id}/bills/${bill_id}/actions/${type}`,
      {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then(async (res) => {
      if (res.ok) {
        const data = await res.json();
        dispatch({ type: UPDATE_BILL, payload: data });
      }
    });
  };
  const setBillFilter = (filter) =>
    dispatch({ type: SET_BILLS_FILTER, payload: filter });

  //pdf
  const createPDFUrl = async (bill_id, name) => {
    const { token, contractor_id } = getAuthData();

    await fetch(
      `${API_URL}/partners/contractors/${contractor_id}/bills/${bill_id}/pdf`,
      {
        method: "POST",
        body: JSON.stringify({}),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then(async (resp) => {
      if (resp.ok) {
        alert("Ошибка");
        const data = await resp.json();
        const actUrl = API_URL + data.act;
        const billUrl = API_URL + data.bill;
        const actName = name.replace("Счет", "Акт");
        downloadFilePDF(actUrl, actName);
        downloadFilePDF(billUrl, name);
      }
    });
  };
  const downloadPDF = async (bill_id, name) => {
    const { token, contractor_id } = getAuthData();

    await fetch(
      `${API_URL}/partners/contractors/${contractor_id}/bills/${bill_id}/pdf`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then(async (response) => {
      if (response.status === 404) {
        alert("Ошибка");
        createPDFUrl(bill_id, name);
      } else {
        const data = await response.json();
        const actUrl = API_URL + data.act;
        const billUrl = API_URL + data.bill;
        const actName = name.replace("Счет", "Акт");
        downloadFilePDF(actUrl, actName);
        downloadFilePDF(billUrl, name);
      }
    });
  };

  //penalties
  const clearPenalties = () => dispatch({ type: CLEAR_PENALTIES });
  const setPenalty = (penalty) =>
    dispatch({ type: SET_PENALTY, payload: penalty });
  const getAllPenalties = async (filter) => {
    dispatch({ type: SET_BILLS_LOADING, payload: true });
    const { token, contractor_id } = getAuthData();

    await fetch(
      `${API_URL}/partners/partners/${contractor_id}/penalties` +
        urlParams(filter),
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then(async (resp) => {
      try {
        const data = await resp.json();
        dispatch({
          type: SET_PENALTIES,
          payload: {
            penalties: data,
            penaltiesHasMore: !!data.length,
          },
        });
      } catch (err) {
        alert("Ошибка: нет данных");
      }
    });
  };
  const agreeOrDisagreePenalty = async (penalty_id, type, body) => {
    const { token, contractor_id } = getAuthData();

    await fetch(
      `${API_URL}/partners/partners/${contractor_id}/penalties/${penalty_id}/actions/${type}`,
      {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then(async (res) => {
      if (res.ok) {
        alert("Ошибка");
        const data = await res.json();
        dispatch({ type: UPDATE_PENALTY, payload: data });
      }
    });
  };
  const setPenaltyFilter = (filter) =>
    dispatch({ type: SET_PENALTY_FILTER, payload: filter });

  return (
    <BillsContext.Provider
      value={{
        error: state.error,
        loading: state.loading,
        bills: state.bills,
        bill: state.bill,
        billFilter: state.billFilter,
        billsHasMore: state.billsHasMore,
        penalties: state.penalties,
        penalty: state.penalty,
        penaltyFilter: state.penaltyFilter,
        penaltiesHasMore: state.penaltiesHasMore,
        clearBills,
        getAllBills,
        getBill,
        setBill,
        acceptOrDeclineBill,
        setBillFilter,
        downloadPDF,
        getAllPenalties,
        clearPenalties,
        setPenalty,
        agreeOrDisagreePenalty,
        setPenaltyFilter,
      }}
    >
      {props.children}
    </BillsContext.Provider>
  );
};

export default BillsState;
