import React, { useReducer } from 'react'
import AuthContext from './authContext'
import AuthReducer, { LocalStorageTypes } from './AuthReducer'
import {
  LOGIN_USER,
  LOADING,
  SET_ALERT,
  REMOVE_ALERT,
  LOGIN_ERROR,
  // SET_KEY,
  // SET_USER,
  // SET_LOGIN,
  SET_TOKEN,
  SET_PARTNER_ID, LOGIN_USER_WITH_TOKEN,
} from '../types';
import { API_URL, AUTH_APU_URL } from "../../../utils/envUtils"

const AuthState = props => {
  const initialState = {
    loading: false,
    alert: null,
    isAuthenticated: false,
    key: '',
    user: '',
    login: '',
    token: '',
    partner_id: ''
  };

  const [state, dispatch] = useReducer(AuthReducer, initialState);

  // Login user
  const loginUser = async (login, pass) => {
    dispatch({ type: LOADING, payload: true })
    const body = { login: login, password: pass }

    const oldAuth = await authWithOldAPI(body)
    const newAuth = await authWithNewAPI(body)

    if (oldAuth || newAuth) {
      setAlert('Вы успешно вошли', 'success')
    } else {
      setAlert('Неверные данные', 'danger')
      dispatch({ type: LOGIN_ERROR })
    }
    dispatch({ type: LOADING, payload: false })
  }

  const authWithOldAPI = async (body) => {
    const { login, password } = body
    // old API auth
    return await fetch(`https://script.google.com/macros/s/AKfycbxIqFt9DzdnB085apVHNbLC6jiPqClksLWhUK1PtpbyCdDsGLRz/exec?login=${login}&password=${password}`)
      .then(async (resp) => {
        const data = await resp.json()
        const user = data.userType[0]
        const key = data.key[0]
        if (user !== 'Bad login or passwor') {
          dispatch({ type: LOGIN_USER, payload: { user, key, login: data.login[0] } })
          return true
        }
        return false
      })
  }

  const authWithNewAPI = async (body) => {
    // new API auth
    return await fetch(`${AUTH_APU_URL}/v1/auth/login`, {
      method: 'POST',
      body: JSON.stringify(body)
    }).then(async res => {
      if (res.status === 401) {
        return false
      } else {
        const data = await res.json();
        if (data.token) {
          dispatch({ type: SET_TOKEN, payload: data.token })
          getContractorId(data.token)
          return true
        }
        return false
      }
    })
  }

  const getContractorId = async (token) => {
    // partner info by token
    await fetch(`${API_URL}/partners/partner`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }).then(async (resp) => {
      const partnerData = await resp.json()
      if (partnerData.id) {
        dispatch({ type: SET_PARTNER_ID, payload: partnerData.contracor_id })
      } else {
        setAlert('Ошибка получения индентификатора партнера', 'danger')
      }
    })
  }

  const loadUser = () => {
    if (localStorage[LocalStorageTypes.USER]
      && localStorage[LocalStorageTypes.KEY]
      && localStorage[LocalStorageTypes.LOGIN]) {
      dispatch({
        type: LOGIN_USER, payload: {
          user: localStorage[LocalStorageTypes.USER],
          key: localStorage[LocalStorageTypes.KEY],
          login: localStorage[LocalStorageTypes.LOGIN]
        }
      })
    }

    if(localStorage[LocalStorageTypes.TOKEN] && localStorage[LocalStorageTypes.PARTNER_ID]) {
      dispatch({
        type: LOGIN_USER_WITH_TOKEN, payload: {
          token: localStorage[LocalStorageTypes.TOKEN],
          partner_id: localStorage[LocalStorageTypes.PARTNER_ID],
        }
      })
    }
    // if (localStorage[LocalStorageTypes.KEY]) {
    //   dispatch({type:SET_KEY, payload: localStorage[LocalStorageTypes.KEY]})
    // }
    // if (localStorage[LocalStorageTypes.LOGIN]) {
    //   dispatch({type:SET_LOGIN, payload: localStorage[LocalStorageTypes.LOGIN]})
    // }
    // if (localStorage[LocalStorageTypes.TOKEN]) {
    //   dispatch({ type: SET_TOKEN, payload: localStorage[LocalStorageTypes.TOKEN] })
    // }
    // if (localStorage[LocalStorageTypes.PARTNER_ID]) {
    //   dispatch({ type: SET_PARTNER_ID, payload: localStorage[LocalStorageTypes.PARTNER_ID] })
    // }
  }

  const logOut = () => {
    dispatch({ type: LOGIN_ERROR })
  }

  const setAlert = (message, type) => {
    dispatch({ type: SET_ALERT, payload: { message, type } })
    setTimeout(() => {
      dispatch({ type: REMOVE_ALERT })
    }, 3000);
  }

  return (
    <AuthContext.Provider
      value={{
        loading: state.loading,
        alert: state.alert,
        isAuthenticated: state.isAuthenticated,
        key: state.key,
        user: state.user,
        login: state.login,
        token: state.token,
        loginUser,
        loadUser,
        logOut,
        setAlert
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;