import React, { useContext } from 'react'
import { useNavigate } from "react-router-dom"
import BillsContext from "../../../Context/billsContext/billsContext"
import { BillStatusTranslation } from "../utils";
import styles from './styles.module.scss'

const BillsTable = ({ bills }) => {
  const navigate = useNavigate()
  const { setBill } = useContext(BillsContext)

  const handleBillClick = (bill) => {
    setBill(bill)
    navigate(`/bills/${bill.id}`)
  }

  return (
    <table>
      <thead className={styles.head}>
        <tr>
          <th>Название</th>
          <th>Описание</th>
          <th>Период</th>
          <th>Статус</th>
          <th>Всего</th>
        </tr>
      </thead >

      <tbody>
        {bills.map(bill => (
          <tr
            className={styles.tableRow}
            key={bill.id}
            onClick={() => handleBillClick(bill)}
          >
            <td>{bill.name}</td>
            <td>{bill.description}</td>
            <td>{bill.period}</td>
            <td>{BillStatusTranslation[bill.state.name]}</td>
            <td>{bill.total_value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default BillsTable;