import React, { useContext } from 'react'
import { useNavigate } from "react-router-dom"
import BillsContext from "../../../../Context/billsContext/billsContext"
import { SubmitType } from "../index"
import { BillStatusTranslation } from "../../utils";
import styles from "./styles.module.scss"

const BillsInfoCard = ({ bill, toggleModal }) => {
  const { clearBills, downloadPDF } = useContext(BillsContext)
  const navigate = useNavigate()

  const isDisabled = () => {
    return bill.state.name === 'accepted' || bill.state.name === 'declined'
  }

  const isAcceptedOrPaid = () => {
    return bill.state.name === 'accepted' || bill.state.name === 'paid'
  }

  const onBack = () => {
    clearBills()
    navigate(-1)
  }


  return (
    <div className={styles.billInfo}>
      <div className={styles.billHeader}>
        <div>
          <span className={styles.back} onClick={onBack}>&#10094;</span>
          <span>{bill.name}</span>
          <span className='tag'>{BillStatusTranslation[bill.state.name]}</span>
        </div>

        <div className={styles.billActions}>
          {
            isAcceptedOrPaid()
              ? (
                <button
                  className='btn btn-main'
                  onClick={() => downloadPDF(bill.id, bill.name)}
                >
                  Скачать PDF
                </button>
              )
              : (
                <>
                  <button
                    className={`btn ${isDisabled() ? 'btn-disabled' : 'btn-main'}`}
                    onClick={() => toggleModal(SubmitType.accept)}
                    disabled={isDisabled()}
                  >
                    Принять
                  </button>
                  <button
                    className={`btn ${isDisabled() ? 'btn-disabled' : 'btn-danger'}`}
                    onClick={() => toggleModal(SubmitType.cancel)}
                    disabled={isDisabled()}
                  >
                    Отклонить
                  </button>
                </>
              )
          }

        </div>

      </div>

      <div><b>Период:</b> {bill.period}</div>
      <div><b>Описание:</b> {bill.description}</div>
      <div><b>Сумма счета:</b> {bill.total_value}</div>
      <div><b>Создан:</b> {new Date(bill.state.created).toLocaleString()}</div>
    </div>
  );
};

export default BillsInfoCard;