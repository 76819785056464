import React, { useContext, useState } from 'react'
import { useNavigate } from "react-router-dom"
import BillsContext from "../../../Context/billsContext/billsContext"
import Modal from "../../../Layout/Modal";
import { PenaltyStatusTranslation } from "../utils"
import styles from "./styles.module.scss"

export const PenaltySubmitType = {
  agree: 'agree',
  disagree: 'disagree'
}


const Penalty = () => {
  const { clearPenalties, penalty, agreeOrDisagreePenalty } = useContext(BillsContext)
  const navigate = useNavigate()

  const [isModal, setModal] = useState({ open: false, type: null, comment: '' })
  const toggleModal = (typeProp) => setModal({
    ...isModal,
    open: !isModal.open,
    type: typeProp ? typeProp : isModal.type
  })
  const handleCommentChange = (e) => setModal({ ...isModal, comment: e.target.value ? e.target.value : '' })

  const isDisabled = () => {
    return penalty.state.name === 'agreed' || penalty.state.name === 'disagreed' || penalty.state.name === 'applied'
  }

  const onBack = () => {
    clearPenalties()
    navigate(-1)
  }

  const handleAgreeOrDisagreePenalty = () => {
    agreeOrDisagreePenalty(penalty.id, isModal.type, { comment: isModal.comment })
    setModal({ open: false, type: null, comment: '' })
  }

  return (
    <>
      <div className={`container ${styles.penaltyInfo}`}>
        <div className={styles.penaltyHeader}>
          <div>
            <span className={styles.back} onClick={onBack}>&#10094;</span>
            <span>{penalty.name}</span>
            <span className='tag'>{PenaltyStatusTranslation[penalty.state.name]}</span>
          </div>

          <div className={styles.penaltyActions}>
            {
              <>
                <button
                  className={`btn ${isDisabled() ? 'btn-disabled' : 'btn-main'}`}
                  onClick={() => toggleModal(PenaltySubmitType.agree)}
                  disabled={isDisabled()}
                >
                  Согласен
                </button>
                <button
                  className={`btn ${isDisabled() ? 'btn-disabled' : 'btn-danger'}`}
                  onClick={() => toggleModal(PenaltySubmitType.disagree)}
                  disabled={isDisabled()}
                >
                  Не согласен
                </button>
              </>
            }
          </div>

        </div>

        <div><b>Адресс:</b> {penalty.carwash_address}</div>
        <div><b>Сумма штрафа:</b> {penalty.value}</div>
        <div><b>Создан:</b> {new Date(penalty.created).toLocaleString()}</div>
        <div><b>Обновлено:</b> {new Date(penalty.state.created).toLocaleString()}</div>
        <div><b>Комментарий:</b> {penalty.state.commment}</div>
      </div>

      <Modal show={isModal.open} handleClose={toggleModal} withConfirm onSubmit={handleAgreeOrDisagreePenalty}>
        <div className={styles.modalTitle}>{isModal.type === PenaltySubmitType.agree ? 'Согласен' : 'Не согласен'}</div>
        <div>
          <label style={{ fontSize: '14px' }}>Комментарий: </label>
          <input value={isModal.comment} onChange={handleCommentChange} style={{ width: '74%' }} />
        </div>
      </Modal>
    </>
  );
};

export default Penalty;