import React from "react"
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom"
import Navbar from "./Сomponents/Layout/Navbar"
import RequireAuth from "./Сomponents/Pages/RequireAuth"
import RequireAuthWithType from "./Сomponents/Pages/RequireAuthWithType"
import RequireAuthWithToken from "./Сomponents/Pages/RequireAuthWithToken"
import FirstPage from "./Сomponents/Pages/FirstPage"
import Search from "./Сomponents/Pages/Search"
import Image from "./Сomponents/Pages/Image"
import Logs from "./Сomponents/Pages/Logs"
import Edit from "./Сomponents/Pages/Edit"
import Alert from "./Сomponents/Layout/Alert"
import Archive from "./Сomponents/Pages/Archive"
import Fines from "./Сomponents/Pages/Fines"
import Collation from "./Сomponents/Pages/Collation"
import Feedback from "./Сomponents/Pages/Feedback"
import Materials from "./Сomponents/Pages/Materials"
import StateProvider from "./Сomponents/Layout/StateProvider"
import Bills from "./Сomponents/Pages/Bills"
import Bill from "./Сomponents/Pages/Bills/Bill"
import Penalties from "./Сomponents/Pages/Penalties"
import Penalty from "./Сomponents/Pages/Penalties/Penalty"

function App() {
  return (
    <StateProvider>
      <Router>
        <div className="App">
          <Navbar/>
          <Alert/>

          <Routes>
            <Route path='/' element={<FirstPage />} />
            <Route path='/search' element={<RequireAuth component={Search}/>}/>
            <Route path='/image' element={<RequireAuth component={Image}/>}/>
            <Route path='/edit' element={<RequireAuth component={Edit}/>}/>
            
            <Route path='/bills' element={<RequireAuthWithToken component={Bills}/>}/>
            <Route path='/bills/:id' element={<RequireAuthWithToken component={Bill}/>}/>
            <Route path='/penalties' element={<RequireAuthWithToken component={Penalties}/>}/>
            <Route path='/penalties/:id' element={<RequireAuthWithToken component={Penalty}/>}/>

            <Route path='/logs' element={<RequireAuthWithType type='admin' component={Logs}/>}/>

            <Route path='/archive' element={<RequireAuthWithType type='washing' component={Archive}/>}/>
            <Route path='/fines' element={<RequireAuthWithType type='washing' component={Fines}/>}/>
            <Route path='/collation' element={<RequireAuthWithType type='washing' component={Collation}/>}/>
            <Route path='/feedback' element={<RequireAuthWithType type='washing' component={Feedback}/>}/>
            <Route path='/materials' element={<RequireAuthWithType type='washing' component={Materials}/>}/>
            <Route path='*' element={<Navigate to='/' />} />
          </Routes>
        </div>
      </Router>
    </StateProvider>
  );
}

export default App;
