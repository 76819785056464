import {
  LOGIN_USER,
  LOGIN_USER_WITH_TOKEN,
  LOADING,
  SET_ALERT,
  REMOVE_ALERT,
  LOGIN_ERROR,
  SET_KEY,
  SET_USER,
  SET_LOGIN,
  SET_TOKEN,
  SET_PARTNER_ID,
} from '../types';

export const LocalStorageTypes = {
  USER: 'ucar-partners-user',
  KEY: 'ucar-partners-key',
  LOGIN: 'ucar-partners-login',
  TOKEN: 'ucar-partners-token',
  PARTNER_ID: 'ucar-partners-id'
}

export default (state, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case SET_ALERT:
      return {
        ...state,
        loading: false,
        alert: action.payload
      };
    case REMOVE_ALERT:
      return {
        ...state,
        alert: null
      }
    case LOGIN_USER:
      localStorage.setItem(LocalStorageTypes.USER, action.payload.user)
      localStorage.setItem(LocalStorageTypes.KEY, action.payload.key)
      localStorage.setItem(LocalStorageTypes.LOGIN, action.payload.login)
      return {
        ...state,
        user: action.payload.user,
        key: action.payload.key,
        login: action.payload.login,
        isAuthenticated: true,
        loading: false
      }
    case LOGIN_USER_WITH_TOKEN:
      localStorage.setItem(LocalStorageTypes.TOKEN, action.payload.token)
      localStorage.setItem(LocalStorageTypes.PARTNER_ID, action.payload.partner_id)
      return {
        ...state,
        token: action.payload.token,
        partner_id: action.payload.partner_id,
        isAuthenticated: true,
        loading: false
      }
    case LOGIN_ERROR:
      localStorage.removeItem(LocalStorageTypes.LOGIN)
      localStorage.removeItem(LocalStorageTypes.KEY)
      localStorage.removeItem(LocalStorageTypes.USER)
      localStorage.removeItem(LocalStorageTypes.TOKEN)
      localStorage.removeItem(LocalStorageTypes.PARTNER_ID)
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        user: '',
        key: '',
        login: '',
        token: '',
        partner_id: ''
      }
    case SET_PARTNER_ID:
      localStorage.setItem(LocalStorageTypes.PARTNER_ID, action.payload)
      return {
        ...state,
        partner_id: action.payload
      }
    case SET_TOKEN:
      localStorage.setItem(LocalStorageTypes.TOKEN, action.payload)
      return {
        ...state,
        token: action.payload,
        isAuthenticated: true
      }
    case SET_USER:
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true
      }
    case SET_KEY:
      return {
        ...state,
        key: action.payload,
        isAuthenticated: true
      }
    case SET_LOGIN:
      return {
        ...state,
        login: action.payload,
        isAuthenticated: true
      }
    default:
      return state;
  }
};