import React from "react";
import styles from "../styles.module.scss";

const BillItemsTable = ({ billItems }) => {
  return (
    <div>
      {billItems?.map((bill) => (
        <div className={styles.table} key={bill.key}>
          <div className={styles.tableTitle} style={{ paddingLeft: "2rem" }}>
            {bill.name}
          </div>
          <div className={styles.tableDescription}>{bill.description}</div>
          <div className={styles.tableLength} />
          <div className={styles.tableTotal}>{bill.value}</div>
        </div>
      ))}
    </div>
  );
};

export default BillItemsTable;
