import React from "react";

const Modal = ({ handleClose, show, children, withConfirm = false, onSubmit }) => {
  const showHideClassName = show ? "display-block" : "display-none";

  return (
    <div className={showHideClassName} style={modal}>
      <section className="modal-main">
        {children}
        {
          withConfirm
            ? (
              <div style={{
                display: 'flex', gap: '8px', justifyContent: 'end', marginTop: '16px'
              }}>
                <button className='btn btn-main' onClick={onSubmit}>Подтвердить</button>
                <button className='btn btn-info' onClick={handleClose}>Отменить</button>
              </div>
            )
            : (
              <a onClick={handleClose} className="btn btn-light btn-block">
                Закрыть
              </a>
            )
        }

      </section>
    </div>
  );
};

const modal = {
  boxSizing: "border-box",
  position: "fixed",
  borderRadius: 0,
  margin: 0,
  padding: 0,
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0.55)",
};

export default Modal;
