import React, { useState, useEffect, useContext } from 'react'
import AuthContext from '../Context/authContext/authContext'
// import FetchContext from '../Context/fetchContext/fetchContext'
// import News from '../Layout/News'
import Login from '../Layout/Login'
import Spinner from 'react-bootstrap/Spinner'
import ListGroup from 'react-bootstrap/ListGroup'

const FirstPage = () => {
  const [loadingPartners, setLoadingPartners] = useState(false)
  const [loadingNotif, setLoadingNotif] = useState(false)

  const [partners, setPartners] = useState([])
  const [notif, setNotif] = useState([])

  const { isAuthenticated, login, token, loadUser } = useContext(AuthContext)
  // const { getNews } = useContext(FetchContext)

  useEffect(() => {
    loadUser()
  }, [])

  const getPartners = async (mount) => {
    if (mount) {
      setLoadingPartners(true)
      const res = await fetch(`https://script.google.com/macros/s/AKfycbxIqFt9DzdnB085apVHNbLC6jiPqClksLWhUK1PtpbyCdDsGLRz/exec?request=partnersList&user=${login}`)
      const data = await res.json()
      setPartners(data.partnersList)
      setLoadingPartners(false)
    }
  }

  const getNotifications = async (mount) => {
    if (mount) {
      setLoadingNotif(true)
      const res = await fetch(`https://script.google.com/macros/s/AKfycbxIqFt9DzdnB085apVHNbLC6jiPqClksLWhUK1PtpbyCdDsGLRz/exec?request=notificationList&user=${login}`)
      const data = await res.json()
      setNotif(data.notificationList)
      setLoadingNotif(false)
    }
  }

  useEffect(() => {
    let mount = true;
    if (login) {
      getPartners(mount)
      getNotifications(mount)
    }
    return function cleanup() {
      mount = false
    }
  }, [login])

  useEffect(() => {
    refresh()
  }, [token])

  const refresh = () => {
    // getNews()
    getPartners()
    getNotifications()
  }

  const Partners = () => {
    return (
      <table>
        <thead>
        <tr>
          {partners.map(p => <th key={p.name}>{p.name}</th>)}
        </tr>
        </thead>
        <tbody>
        <tr>
          {partners.map(p => <td key={p.name}>{p.status ?
            <i className="far fa-check-circle" style={{ color: '#A7D08C' }}/> :
            <i className="far fa-circle" style={{ color: '#F5B041' }}/>}</td>)}
        </tr>
        </tbody>
      </table>
    );
  }

  const Notifications = () => {
    return (
      <ListGroup>
        {notif.map((n, ind) =>
          <ListGroup.Item key={ind}>
            <i className="far fa-bell"/> {n.text}
          </ListGroup.Item>
        )}
      </ListGroup>
    )
  }

  return (
    <>
       {/*{isAuthenticated ? <Redirect to='/archive'/> : <Login/> }*/}
      {isAuthenticated
        ?
        <>
          {/*<Navigate to='/' />*/}
          <div className="container" id="main-page">
            <h4>Главная <i className="fas fa-sync-alt refresh-logs" onClick={refresh}/></h4>
            <div id="top">
              {
                login && <PartnersWithNotif
                  loadingPartners={loadingPartners}
                  loadingNotif={loadingNotif}
                  Partners={Partners}
                  Notifications={Notifications}
                />
              }
            </div>
            <div id="bottom">
              {/*<News/>*/}
              {/*<div style={{ paddingRight: '2rem' }}>*/}
              {/*  <iframe*/}
              {/*    title='telegram widget'*/}
              {/*    frameBorder="0"*/}
              {/*    scrolling="no"*/}
              {/*    horizontalscrolling="no"*/}
              {/*    verticalscrolling="no"*/}
              {/*    width="100%"*/}
              {/*    height="540px"*/}
              {/*    async*/}
              {/*    src="https://tgwidget.com/channel/v2.0/?id=5fc529aa83ba8883348b4569"*/}
              {/*  />*/}
              {/*</div>*/}
            </div>
          </div>
        </>
        : <Login/>
      }
    </>
  )
}

const PartnersWithNotif = ({loadingPartners, loadingNotif, Partners, Notifications}) => {
  return (
    <>
      <div>
        <h5>Вы работаете с:</h5>
        {loadingPartners ? <Spinner animation="grow"/> : <Partners/>}
      </div>
      <div>
        <h5>Уведомления:</h5>
        {loadingNotif ? <Spinner animation="grow"/> : <Notifications/>}
      </div>
    </>
  )
}

export default FirstPage
