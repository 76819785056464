import React from 'react'
import AuthState from '../Context/authContext/AuthState'
import FilterState from '../Context/filterContext/FilterState'
import BillsState from '../Context/billsContext/BillsState'
import FetchState from '../Context/fetchContext/FetchState'

const StateProvider = (props) => {
  return (
    <AuthState>
      <FilterState>
        <BillsState>
          <FetchState>
            {props.children}
          </FetchState>
        </BillsState>
      </FilterState>
    </AuthState>
  );
};

export default StateProvider;