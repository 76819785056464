import React, { useContext, useEffect, useState } from 'react'
import BillsContext from "../../../Context/billsContext/billsContext"
import { PenaltyStatusTypes } from "../../../Context/billsContext/BillsState"
import { PenaltyStatusTranslation } from "../utils"
import styles from "./styles.module.scss"

const PenaltiesFilter = () => {
  const { setPenaltyFilter, penaltyFilter } = useContext(BillsContext)
  const [period, setPeriod] = useState({
    begin: penaltyFilter.begin ?? '',
    end: penaltyFilter.end ?? '',
  })

  const handlePeriodChange = (e, type) => {
    const value = e.target.value
    if(type === 'from') {
      setPeriod({...period, begin: value})
    } else if (type === 'to') {
      setPeriod({...period, end: value})
    }
  }

  const handleRangeClear = () => {
    setPenaltyFilter({...penaltyFilter, begin: undefined, end: undefined})
    setPeriod({begin: '', end: ''})
  }

  const handleStatusChange = (e) =>
    setPenaltyFilter({...penaltyFilter, state: e.target.value === 'all' ? undefined : e.target.value})

  useEffect(() => {
    if(Boolean(period.begin) && Boolean(period.end)) {
      setPenaltyFilter({...penaltyFilter, begin: period.begin, end: period.end})
    }
  }, [period])

  return (
    <div className={styles.container}>
      <div>
        <label htmlFor="from">Период: </label>
        <input
          type="date"
          id="from"
          name="from"
          value={period.begin}
          onKeyDown={(e) => e.preventDefault()}
          onChange={(e) => handlePeriodChange(e, 'from')}
        />
        <label htmlFor="to" className={styles.arrowRight}>&#8640;</label>
        <input
          type="date"
          id="to"
          name="to"
          value={period.end}
          onKeyDown={(e) => e.preventDefault()}
          onChange={(e) => handlePeriodChange(e, 'to')}
        />
        <span className={styles.clearDate} onClick={handleRangeClear}>&#10006;</span>
      </div>
      <div>
        <label htmlFor="status">Статус: </label>
        <select name='status' className={styles.selectStatus} value={penaltyFilter.state} onChange={handleStatusChange}>
          <option value='all' />
          {
            Object.values(PenaltyStatusTypes).map(status => (
              <option key={status} value={status}>{PenaltyStatusTranslation[status]}</option>
            ))
          }
        </select>
      </div>
    </div>
  );
};

export default PenaltiesFilter;