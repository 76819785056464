
export const parseBillItemsByGroups = (items) => {
  const itemsByGroups = {Прочее: []}

  for (let i = 0; i < items.length; i++) {
    const item = items[i]

    if (!item.group) {
      itemsByGroups.Прочее.push(item)
    } else if (Boolean(itemsByGroups[item.group])) {
      itemsByGroups[item.group].push(item)
    } else {
      itemsByGroups[item.group] = []
      itemsByGroups[item.group].push(item)
    }
  }


  // get keys
  let alphabeticallySortedKeys = Object.keys(itemsByGroups).sort((a, b) => a < b ? -1 : 1)

  // put Прочее to the end
  if (!itemsByGroups.Прочее.length) {
    alphabeticallySortedKeys = alphabeticallySortedKeys.filter(title => title !== 'Прочее')
    delete itemsByGroups.Прочее
  }

  const groupTitles = alphabeticallySortedKeys.map(item =>
    ({groupTitle: item, key: item, length: itemsByGroups[item]?.length || 0, total: sumValues(itemsByGroups[item] ?? [])}))

  return [itemsByGroups, groupTitles]
}

/**
 * It takes an array of objects with a value property, and returns the sum of all the values
 * @param {{value: number}[]} array - {value: number}[]
 * @returns The sum of all the values in the array.
 */
 export const sumValues = (array) => {
  return array.reduce((total, obj) => total + obj.value, 0)
}
