import React, { useContext, useMemo, useState, useEffect } from "react";
import { Accordion, Card } from "react-bootstrap";
import BillsContext from "../../../Context/billsContext/billsContext";
import Modal from "../../../Layout/Modal";
import BillItemsTable from "./BillItemsTable";
import BillsInfoCard from "./BillsInfoCard";
import { parseBillItemsByGroups } from "./utils";
import styles from "./styles.module.scss";

export const SubmitType = {
  accept: "accept",
  cancel: "decline",
};

const Bill = () => {
  const { bill, acceptOrDeclineBill, getBill } = useContext(BillsContext);
  const [itemsByGroups, groupTitles] = useMemo(
    () => parseBillItemsByGroups(bill.items || []),
    [bill]
  );
  const [billItems, setBillItems] = useState([]);

  const [isModal, setModal] = useState({
    open: false,
    type: null,
    comment: "",
  });
  const toggleModal = (typeProp) =>
    setModal({
      ...isModal,
      open: !isModal.open,
      type: typeProp ? typeProp : isModal.type,
    });
  const handleCommentChange = (e) =>
    setModal({ ...isModal, comment: e.target.value ? e.target.value : "" });

  const handleAcceptOrDecline = () => {
    acceptOrDeclineBill(bill.id, isModal.type, { comment: isModal.comment });
    setModal({ open: false, type: null, comment: "" });
  };

  const [activeKey, setActiveKey] = useState([]);
  const handleAccordion = (key) => {
    if (activeKey.includes(key)) {
      setActiveKey(activeKey.filter((active) => active !== key));
    } else {
      setActiveKey([...activeKey, key]);
    }
  };

  useEffect(() => {
    const fetchBillData = async () => {
      const data = await getBill(bill.id);
      setBillItems(data?.items);
    };

    fetchBillData();
  }, [bill.id, getBill]);

  return (
    <>
      <div className="container">
        <BillsInfoCard bill={bill} toggleModal={toggleModal} />

        <Accordion className={styles.accordion} activeKey={activeKey}>
          <div className={styles.table}>
            <div className={styles.tableTitle}>Название</div>
            <div className={styles.tableDescription}>Описание</div>
            <div className={styles.tableLength}>Количество</div>
            <div className={styles.tableTotal}>Сумма</div>
          </div>
          {groupTitles.map((title) => (
            <Card className={styles.card}>
              <Accordion.Item eventKey={title.key}>
                <Card.Header
                  onClick={() => handleAccordion(title.key)}
                  className={styles.cardHeader}
                >
                  <div className={styles.table}>
                    <div className={styles.tableTitle}>
                      <i
                        className={`far ${
                          activeKey.includes(title.key)
                            ? "fa-minus-square"
                            : "fa-plus-square"
                        } fa-1x ${styles.plus}`}
                      />
                      {title.groupTitle}
                    </div>
                    <div className={styles.tableDescription} />
                    <div className={styles.tableLength}>{title.length}</div>
                    <div className={styles.tableTotal}>{title.total}</div>
                  </div>
                </Card.Header>
                <Accordion.Collapse eventKey={title.key}>
                  <Card.Body className={styles.cardBody}>
                    <BillItemsTable
                      billItems={itemsByGroups[title.key] || []}
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Accordion.Item>
            </Card>
          ))}
        </Accordion>
        <BillItemsTable billItems={billItems} />
      </div>

      <Modal
        show={isModal.open}
        handleClose={toggleModal}
        withConfirm
        onSubmit={handleAcceptOrDecline}
      >
        <div className={styles.modalTitle}>
          {isModal.type === SubmitType.accept ? "Принять" : "Отклонить"} счет
        </div>
        <div>
          <label style={{ fontSize: "14px" }}>Комментарий: </label>
          <input
            value={isModal.comment}
            onChange={handleCommentChange}
            style={{ width: "74%" }}
          />
        </div>
      </Modal>
    </>
  );
};

export default Bill;
