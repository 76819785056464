import React, { useContext } from 'react'
import { useNavigate } from "react-router-dom"
import BillsContext from "../../../Context/billsContext/billsContext"
import { PenaltyStatusTranslation } from "../utils"
import styles from "./styles.module.scss"

const PenaltiesTable = ({penalties}) => {
  const navigate = useNavigate()
  const { setPenalty } = useContext(BillsContext)

  const handlePenaltyClick = (penalty) => {
    setPenalty(penalty)
    navigate(`/penalties/${penalty.id}`)
  }

  return (
    <table>
      <thead className={styles.head}>
      <tr>
        <th>Название</th>
        <th>Адресс</th>
        <th>Статус</th>
        <th>Сумма</th>
        <th>Создан</th>
      </tr>
      </thead >

      <tbody>
      {penalties.map(penalty => (
        <tr
          className={styles.tableRow}
          key={penalty.id}
          onClick={() => handlePenaltyClick(penalty)}
        >
          <td align='left'>{penalty.name}</td>
          <td>{penalty.carwash_address}</td>
          <td>{PenaltyStatusTranslation[penalty.state.name]}</td>
          <td>{penalty.value}</td>
          <td>{new Date(penalty.created).toLocaleString()}</td>
        </tr>
      ))}
      </tbody>
    </table>
  );
};

export default PenaltiesTable;