import React, { useContext, useEffect, useRef } from 'react'
import BillsContext from "../../Context/billsContext/billsContext"
import Spinner from "../../Layout/Spinner2"
import useIntersectionObserver from "../../../utils/hooks/useIntersectionObserver";
import BillsTable from "./BillsTable"
import BillsFilter from "./BillsFilter"

const Bills = () => {
  const { bills, getAllBills, loading, clearBills, billFilter, billsHasMore } = useContext(BillsContext)
  const observableRef = useRef(null)
  const entry = useIntersectionObserver(observableRef, {})
  const isVisible = !!entry?.isIntersecting

  useEffect(() => {
    clearBills()
    getAllBills({...billFilter, limit: 20, offset: 0})
  }, [billFilter])

  useEffect(() => {
    if(isVisible && !loading && billsHasMore) {
      getAllBills({...billFilter, limit: 20, offset: bills.length})
    }
  }, [isVisible])


  return (
    <div className='container'>
      <BillsFilter/>
      <hr/>
      {
        bills.length
          ? <BillsTable bills={bills}/>
          : !loading && <div>Счета отсутсвуют</div>
      }
      {loading && <Spinner />}
      <div ref={observableRef} />
    </div>
  );
};

export default Bills;