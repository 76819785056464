import React, { useContext, useEffect, useRef } from 'react'
import billsContext from "../../Context/billsContext/billsContext"
import PenaltiesTable from "./PenaltiesTable"
import Spinner from "../../Layout/Spinner2"
import PenaltiesFilter from "./PenaltiesFilter"
import useIntersectionObserver from "../../../utils/hooks/useIntersectionObserver"

const Penalties = () => {
  const { getAllPenalties, clearPenalties, penalties, loading, penaltiesHasMore, penaltyFilter } = useContext(billsContext)
  const observableRef = useRef(null)
  const entry = useIntersectionObserver(observableRef, {})
  const isVisible = !!entry?.isIntersecting

  useEffect(() => {
    clearPenalties()
    getAllPenalties({...penaltyFilter, limit: 20, offset: 0})
  }, [penaltyFilter])

  useEffect(() => {
    if(isVisible && !loading && penaltiesHasMore) {
      getAllPenalties({...penaltyFilter, limit: 20, offset: penalties.length})
    }
  }, [isVisible])

  return (
    <div className='container'>
      <PenaltiesFilter />
      <hr />
      {
        penalties.length
          ? <PenaltiesTable penalties={penalties}/>
          : !loading && <div>Штрафы отсутсвуют</div>
      }
      {loading && <Spinner />}
      <div ref={observableRef} />
    </div>
  );
};

export default Penalties;