import {
  SET_BILLS_LOADING,
  SET_BILLS,
  CLEAR_BILLS,
  SET_BILL,
  UPDATE_BILL,
  SET_BILLS_FILTER,
  SET_PENALTIES,
  CLEAR_PENALTIES,
  SET_PENALTY,
  UPDATE_PENALTY,
  SET_PENALTY_FILTER,
} from '../types'

export default (state, action) => {
  switch(action.type) {
    // penalties
    case SET_PENALTY_FILTER:
      return {
        ...state,
        penaltyFilter: action.payload
      }
    case UPDATE_PENALTY:
      return {
        ...state,
        penalty: action.payload
      }
    case SET_PENALTY:
      return {
        ...state,
        penalty: action.payload
      }
    case SET_PENALTIES: {
      return {
        ...state,
        penalties: [...state.penalties, ...action.payload.penalties],
        penaltiesHasMore: action.payload.penaltiesHasMore,
        loading: false
      }
    }
    case CLEAR_PENALTIES: {
      return {
        ...state,
        penalties: []
      }
    }
    
    // bills
    case SET_BILLS_FILTER:
      return {
        ...state,
        billFilter: action.payload
      }
    case UPDATE_BILL:
      return {
        ...state,
        bill: action.payload
      }
    case SET_BILL:
      return {
        ...state,
        bill: action.payload,
      }
    case SET_BILLS:
      return {
        ...state,
        bills: [...state.bills, ...action.payload.bills],
        billsHasMore: action.payload.billsHasMore,
        loading: false
      }
    case CLEAR_BILLS:
      return {
        ...state,
        bills: []
      }
    case SET_BILLS_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    default: return state
  }
}

