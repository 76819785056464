import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import AuthContext from "../Context/authContext/authContext";

const RequireAuthWithType = ({ component: Component, type }) => {
  const authContext = useContext(AuthContext);
  const { isAuthenticated, loading, user } = authContext;

  return isAuthenticated && !loading
    ? user === type && <Component />
    : <Navigate to="/" replace />
};

export default RequireAuthWithType;
