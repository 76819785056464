import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import AuthContext from "../Context/authContext/authContext";

const RequireAuthWithType = ({ component: Component }) => {
  const authContext = useContext(AuthContext);
  const { isAuthenticated, loading, token } = authContext;

  return isAuthenticated && !loading && token
    ? <Component />
    : <Navigate to="/" replace />
};

export default RequireAuthWithType;
