import React, {useContext} from 'react'
import { Navigate } from 'react-router-dom'
import AuthContext from '../Context/authContext/authContext'

const RequireAuth = ({ component: Component }) => {
  const authContext = useContext(AuthContext)
  const {isAuthenticated, loading, user} = authContext;

  return isAuthenticated && !loading && user
    ? (user === 'admin' || user === 'operator') && <Component  />
    : <Navigate to='/' replace />
}

export default RequireAuth
