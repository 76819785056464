import React, { useContext, useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";

import "react-datepicker/dist/react-datepicker.css";
import { BillStatusTypes } from "../../../Context/billsContext/BillsState";
import BillsContext from "../../../Context/billsContext/billsContext";
import { BillStatusTranslation } from "../utils";
import styles from "./styles.module.scss";

registerLocale("ru", ru);

const BillsFilter = () => {
  const { setBillFilter, billFilter } = useContext(BillsContext);
  const [period, setPeriod] = useState({
    start: billFilter.start ?? "",
    end: billFilter.end ?? "",
  });

  const handlePeriodChange = (e, type) => {
    const value = e.target.value
    if(type === 'from') {
      setPeriod({...period, start: value})
    } else if (type === 'to') {
      setPeriod({...period, end: value})
    }
  }

  const handleRangeClear = () => {
    setBillFilter({ ...billFilter, start: undefined, end: undefined });
    setPeriod({ start: "", end: "" });
  };

  const handleStatusChange = (e) =>
    setBillFilter({
      ...billFilter,
      state: e.target.value === "all" ? undefined : e.target.value,
    });

  useEffect(() => {
    if (Boolean(period.start) && Boolean(period.end)) {
      const start = new Date(period.start).toISOString().substring(0, 10);
      const end = new Date(period.end).toISOString().substring(0, 10);
      console.log(start, end);
      setBillFilter({ ...billFilter, start, end });
    }
  }, [period]);

  return (
    <div className={styles.container}>
      <div className={styles.period}>
        <label htmlFor="from">Период: </label>
        <input
          type="date"
          id="from"
          name="from"
          value={period.start}
          onKeyDown={(e) => e.preventDefault()}
          onChange={(e) => handlePeriodChange(e, 'from')}
        />
        <label htmlFor="to" className={styles.arrowRight}>
          &#8640;
        </label>
        <input
          type="date"
          id="to"
          name="to"
          value={period.end}
          onKeyDown={(e) => e.preventDefault()}
          onChange={(e) => handlePeriodChange(e, 'to')}
        />
        <span className={styles.clearDate} onClick={handleRangeClear}>
          &#10006;
        </span>
      </div>
      <div>
        <label htmlFor="status">Статус: </label>
        <select
          name="status"
          className={styles.selectStatus}
          value={billFilter.state}
          onChange={handleStatusChange}
        >
          <option value="all" />
          {Object.values(BillStatusTypes).map((status) => (
            <option key={status} value={status}>
              {BillStatusTranslation[status]}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default BillsFilter;
