import { LocalStorageTypes } from "../Сomponents/Context/authContext/AuthReducer";

export const getAuthData = () => {
  const contractor_id = localStorage.getItem(LocalStorageTypes.PARTNER_ID)
  const token = localStorage.getItem(LocalStorageTypes.TOKEN)

  return {
    contractor_id,
    token
  }
}